import './ListProducts.css';

export default function ListProducts(props){
    const {productCount,productList} = props;
	const filteredProductList = productList.filter((prod)=>
		{
			if(prod.quantity > 0) 
				return prod;
			else
				return false;
		}
	);


	const productListUI = filteredProductList.map(
		(item,index)=>
		{
				return (
					<tr key={item.id}>
						<td>{index+1}</td>
						<td>{item.name}</td>
						<td>{item.quantity}</td>
					</tr>
				);
		}
	);

    return (
         <>
			{
				filteredProductList.length > 0 ? 
				<section id="ListProducts" className="ListProducts vh-80">
					<h1 className='cartHeading'>Cart Products</h1>
					<div className="listTable  table-responsive">
						<table className="table table-striped  table-bordered table-hover ">
							<thead className="tHead">
								<tr>
									<th>#</th>
									<th>Name</th>
									<th>Quantity</th>
								</tr>
							</thead>
							<tbody>
								{productListUI}
								<tr>
									<td colSpan="2" style={{fontWeight:"bold"}}>Total Quantity</td>
									<td style={{fontWeight:"bold"}}>{productCount}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</section> : ''
			}
            
        </>
    );
}