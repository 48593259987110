import './Admin.css';
//import { Link,Outlet,BrowserRouter as Router, Routes,Route } from 'react-router-dom';
import AddProducts from './AddProducts';
export default function Admin(){
    return (
        
        <div className="container Admin" id="Admin">
            {/*<nav>
                <ul>
                    <li><Link to="/admin/AddProducts">Add Products</Link></li>
                    <li><Link to="/admin/EditProducts">Edit Products</Link></li>
                    <li><Link to="/admin/DeleteProducts">Delete Products</Link></li>
                </ul>
            </nav>
            <Router>
                <Routes>
                    <Route />
                </Routes>
            </Router>*/}
            <AddProducts/>
        </div>
    );
}