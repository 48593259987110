import React from "react";
import'./Body.css';
import ImgSlider from "./Imgslider";
import diwaliBanner from '../asserts/images/diwali-2.png';
import diwaliSale from '../asserts/images/diwali-3.png';
import company from '../asserts/images/company-1.jpeg';
import company2 from '../asserts/images/company-2.jpeg';

export default function Body(){
    const images = [
        {
          imgURL: company,
          imgAlt: "img-1"
        },
        {
          imgURL: diwaliBanner,
          imgAlt: "img-2"
        },
        {
          imgURL: company2,
          imgAlt: "img-3"
        },
        {
          imgURL: diwaliSale,
          imgAlt: "img-4"
        }
      ];    return (
        <section id="Body" className="Body">
            <ImgSlider>
                {images.map((image, index) => {
                return <img key={index} src={image.imgURL} alt={image.imgAlt} />;
                })}
            </ImgSlider>
        </section>
    );
}