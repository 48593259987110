import React, { useEffect } from 'react';
import { useState } from 'react';
import './Counter.css';

export default function Counter(props){
    const {index} = props;
    const dec_id = 'dec_'+index;
    const inp_id = 'inp_'+index;
    const inc_id = 'inc_'+index;
    const [counter,setCounter] = useState(0);
    const [alertMessage,setAlert] = useState("");
    const minLimit = 0;
    const maxLimit = 10000;
    const maxAlert = "Maximum Limit Reached "+maxLimit+"!";
    const minAlert = "Item reached zero";

    useEffect(()=>{
        props.getProductQuantity(counter,props.index);
        //getProductQuantity(counter,props.index);
    },[counter])

    const handleCounter = (e,key) => {
        if(key === 'input'){
            const inputValue = parseInt(e.target.value);
            if(inputValue < maxLimit && inputValue > minLimit){
                setCounter(inputValue)
            }
            else{
                setCounter(maxLimit);
                setAlert(maxAlert);
                setTimeout(()=>{
                    setAlert("");
                },3000);
            }

        }

        if(key === 'increment'){
            if( counter < maxLimit){
                setCounter (counter+1);
                setAlert("");
            }
            else {
                setCounter(maxLimit);
                setAlert(maxAlert);
                setTimeout(()=>{
                    setAlert("");
                },3000);
            }
        }
        if(key === 'decrement'){
            if( counter > minLimit){
                setCounter (counter-1);
                setAlert("");
            }
            else {
                setAlert(minAlert);
                setTimeout(()=>{
                    setAlert("");
                },3000);
            }
        }
    }
    
    return (
        <div id="Counter" className="Counter col-md-12">
            <div className='container'>
                <div className='row row-eq-height'>

                        <div className="counterDecrement col-md-3" >
                            <button  className="btn btn-danger" name={dec_id} onClick={(e)=> handleCounter(e,'decrement')} id={dec_id} ><i className='fa fa-minus'></i></button>
                        </div>
                        
                        <div className="counterText col-md-6">
                            <input type='text' pattern="[0-9]*" style={{"textAlign":"center","verticalAlign":"middle","fontWeight":"bold","fontSize":"larger","width":"100%"}} 
                            onChange={(e)=>handleCounter(e,'input')} value={counter} id={inp_id} name={inp_id}></input>
                        </div>
                        <div className="counterIncrement col-md-3">
                            <button  className="btn btn-success" name={inc_id} onClick={(e)=> handleCounter(e,'increment')} id={inc_id}><i className='fa fa-plus'></i></button>
                        </div>

                </div>
                <p className='text-danger'>{alertMessage}</p>
            </div>
        </div>
    );
};